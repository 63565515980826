<template>
  <div class="qr-page">
    <div class="qr-bg">
      <img class="qr-holder-logo" loading="lazy" :src="require('@/assets/images/logo-qr.png')" />
      <h2 class="qr-icon">
        <span><font-awesome-icon :icon="['fa', 'camera']" fixed-width/></span>
        {{ $t.getTranslation('LBL_SCAN_QR_CODE_HERE') }}
      </h2>
      <p v-if="details?.name">
        {{ details.name }}
      </p>
      <p v-else>
        {{ $t.getTranslation('LBL_QR_SCAN_DESCRIPTION') }}
      </p>
      <img v-if="Qrlink" class="qr-holder" loading="lazy" v-lazy="{ src: $h.getQRCode(Qrlink), loading: $t.getTranslation('LBL_LOADING'), error: $t.getTranslation('LBL_ERROR_LOADING') }" />

      <f7-button class="scan-btn" fill @click="submit"> <font-awesome-icon :icon="['fal', 'barcode-read']" fixed-width class="qr-scan" /> {{ $t.getTranslation('LBL_QR_SCAN') }}</f7-button>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, inject, onMounted } from 'vue'
import { helpers } from '@/utils/helpers'
import { useStore } from "@/store";

export default defineComponent({
  name: 'QRScanComponent',
  components: {},
  props: {
    Qrlink: {
      Type: String,
      default: null
    },
    details: {
      Type: Object,
      default: null
    }
  },
  setup(props) {
    const store = useStore();
    
    const qr = ref(null)
    onMounted(() => {
      if (props.Qrlink) {
        qr.value = helpers.getQRCode(props.Qrlink)
      }
    })

    return {
      qr,
      store
    }
  }
})
</script>

<style scoped>
.qr-page {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: 100%;
}
.qr-holder-logo {
  /* position: absolute; */
  z-index: 3;
  top: 70px;
  width: 150px;
}
.qr-bg {
  width: 330px;
  border-radius: 20px;
  background-color: #fff;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 30px;
  text-align: center;
  position: relative;
}
.qr-icon {
  font-size: 18px;
}
.qr-icon span {
  color: #0c4d8f;
  padding-right: 5px;
}
.qr-scan {
  color: #fff;
  font-size: 20px;
  padding-right: 10px;
}
.qr-holder {
  width: 200px;
}
.scan-btn {
  width: 100%;
  text-transform: uppercase;
  font-size: 24px;
  margin-top: 20px;
}
</style>
